import { ImageLoader } from "next/legacy/image"

const imgixDefaultParams = ["auto=format,compress"]

export const isImgixURL = (url: string) => {
  return (
    url.indexOf("images.microcms-assets.io") !== -1 ||
    url.indexOf("imgix.net") !== -1
  )
}

/** fit=maxで調整する imgix x next/image用のloader */
export const imgixImageLoader: ImageLoader = ({ src, quality, width }) => {
  // 既存のパラメーターは全部削除して最適化用のパラメーターを付与する
  // from: https://github.com/vercel/next.js/blob/7aaebee14e830709776bf18801a9018438078c55/packages/next/client/image.tsx#L476
  const bareSrc = processBase(src)
  const params = [...imgixDefaultParams, , "fit=max", "w=" + width]
  return toQuery(bareSrc, params, quality)
}

export const createImgixImagLoader: (options: {
  params?: any[]
  processSize?: (width: number) => any[]
}) => ImageLoader =
  ({ params: optionsParams = [], processSize }) =>
  ({ src, quality, width }) => {
    // 既存のパラメーターは全部削除して最適化用のパラメーターを付与する
    // from: https://github.com/vercel/next.js/blob/7aaebee14e830709776bf18801a9018438078c55/packages/next/client/image.tsx#L476
    const bareSrc = processBase(src)
    const params = [
      ...imgixDefaultParams,
      ...optionsParams,
      ...(processSize?.(width) ?? []),
    ]
    return toQuery(bareSrc, params, quality)
  }

export const imgixFitMaxImageLoader = createImgixImagLoader({
  params: ["fit=max"],
  processSize: (width) => [`w=${width}`],
})

/** 正方形に切り抜くimgix x next/image用のloader */
export const imgixSquareImageLoader = createImgixImagLoader({
  params: ["fit=crop", "crop=faces"],
  processSize: (width) => [`w=${width}`, `h=${width}`],
})

/** 正方形に切り抜くimgix x next/image用のloader */
export const imgixSquareRoundedImageLoader = createImgixImagLoader({
  params: ["fit=crop", "crop=faces", "mask=ellipse"],
  processSize: (width) => [`w=${width}`, `h=${width}`],
})

const processBase = (src: string) => src.replace(/\?.*/, "")

const toQuery = (base: string, params: any[], quality?: number) => {
  let paramsString = ""
  if (quality) {
    params.push("q=" + quality)
  }

  if (params.length) {
    paramsString = "?" + params.join("&")
  }
  return `${base}${paramsString}`
}
