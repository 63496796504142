import { createIcon } from "@chakra-ui/react"

export const LineIcon = createIcon({
  viewBox: "0 0 40 40",
  path: (
    <>
      <g clipPath="url(#a)">
        <path d="M20 40a20 20 0 1 0 0-40 20 20 0 0 0 0 40Z" fill="#00B900" />
        <path
          d="M33.3 18.8C33.3 12.85 27.33 8 20 8 12.67 8 6.7 12.85 6.7 18.8c0 5.33 4.73 9.8 11.12 10.65.44.1 1.03.29 1.17.67.1.39.12.8.05 1.2l-.2 1.14c-.05.33-.26 1.33 1.16.71 1.42-.61 7.67-4.5 10.45-7.73 1.93-2.1 2.85-4.26 2.85-6.64Z"
          fill="#fff"
        />
        <path
          d="M17.3 15.92h-.94a.26.26 0 0 0-.26.26v5.8a.26.26 0 0 0 .26.26h.94a.26.26 0 0 0 .26-.26v-5.8a.26.26 0 0 0-.26-.26ZM23.72 15.92h-.93a.26.26 0 0 0-.26.26v3.44l-2.67-3.58-.01-.04v-.02h-1.13a.26.26 0 0 0-.26.26v5.74a.26.26 0 0 0 .26.26h.95a.26.26 0 0 0 .25-.26v-3.44l2.67 3.59a.3.3 0 0 0 .07.06h1.03a.26.26 0 0 0 .26-.26v-5.75a.26.26 0 0 0-.26-.26M15.05 20.78H12.5v-4.6a.26.26 0 0 0-.26-.26h-.92a.26.26 0 0 0-.26.26v5.8c0 .06.03.13.08.18a.25.25 0 0 0 .18.07h3.72a.26.26 0 0 0 .26-.26v-.93a.26.26 0 0 0-.26-.26M28.87 17.37a.26.26 0 0 0 .26-.26v-.93a.26.26 0 0 0-.26-.26h-3.73a.24.24 0 0 0-.17.08.26.26 0 0 0-.07.17v5.8a.25.25 0 0 0 .07.19c.05.04.11.07.18.07h3.72a.26.26 0 0 0 .26-.26v-.93a.26.26 0 0 0-.26-.26h-2.54v-.97h2.54a.26.26 0 0 0 .26-.26v-.94a.26.26 0 0 0-.26-.26h-2.54v-.98h2.54Z"
          fill="#00B900"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </>
  ),
})
