import { chakra, HTMLChakraProps, SimpleGrid } from "@chakra-ui/react"
import {
  ArticleSimpleCard,
  ArticleSimpleCardProps,
} from "media/components/common/ArticleSimpleCard"
import { PageTitle } from "media/components/common/PageTitle"

export type RecommendEntryListProps = HTMLChakraProps<"section"> & {
  entries: ArticleSimpleCardProps["entry"][]
}

export const RecommendEntryList = ({
  entries,
  ...props
}: RecommendEntryListProps) => {
  if (entries.length === 0) return null

  return (
    <chakra.section id="recommend" w="full" {...props}>
      <PageTitle mainText="オススメ記事" />
      <chakra.div maxW="container.content" mx="auto" w="full">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} w="full">
          {entries.map((entry) => (
            <ArticleSimpleCard
              entry={entry}
              nextLinkPrefetch={false}
              key={entry.id}
              noCategory
              noTags
            />
          ))}
        </SimpleGrid>
      </chakra.div>
    </chakra.section>
  )
}
