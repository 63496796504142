import {
  Box,
  BoxProps,
  chakra,
  Grid,
  GridItem,
  HStack,
  Image,
  Skeleton,
  VStack,
} from "@chakra-ui/react"
import { NavLink } from "@~/common/components"
import { imgixURL } from "@~/common/lib/imgix"
import { lightFormat } from "date-fns"
import { Author } from "@~/common/lib/microcms/media"
import React from "react"
import { TagList } from "./TagList"
import { getAuthors } from "./util/adapter"
import { RenderedEntry } from "./util/types"
import { getEntryPagePath } from "media/lib/urls"

export type ArchiveEntry = Pick<RenderedEntry, "id"> &
  EntryListItemProps["entry"]

export type ArchiveProps = {
  title: string

  /** タイトルを画面上に表示するか
   *
   * @default false
   * アクセシビリティのためにタグ自体は残します
   */
  titleVisible?: boolean

  /** リンク */
  next?: string | null
  prev?: string | null
  index?: string | null
  _box?: BoxProps

  entries?: ArchiveEntry[]
  isLoading?: boolean
  error?: string | boolean
}

/** 記事の一覧を表示する
 */
export const Archive = (props: ArchiveProps) => {
  const titleVisible = props.titleVisible ?? true
  return (
    <VStack
      spacing={4}
      mx="auto"
      w="full"
      pt={{ base: 0, md: 2 }}
      {...props._box}
    >
      (
      <chakra.h2
        display={titleVisible ? "block" : "none"}
        aria-hidden={!titleVisible}
        textStyle="h3"
        color="text.mainDark"
        mt={{ base: 5, md: 2 }}
        mb={{ base: 2, md: 2 }}
      >
        {props.title}
      </chakra.h2>
      )
      {props.isLoading ? (
        <>
          <EntryListItemSkeleton />
          <EntryListItemSkeleton />
          <EntryListItemSkeleton />
          <EntryListItemSkeleton />
          <EntryListItemSkeleton />
        </>
      ) : props.error ? (
        <chakra.p>
          {typeof props.error === "string"
            ? props.error
            : "データの取得ができませんでした。しばらくしてから再度お試しください。"}
        </chakra.p>
      ) : (
        props.entries?.map((entry) => (
          <EntryListItem w="full" key={entry.id} entry={entry} />
        ))
      )}
      <hr />
      <HStack spacing="3">
        {props.index && <NavLink href={props.index}>記事一覧</NavLink>}
        {props.prev && <NavLink href={props.prev}>前のページ</NavLink>}
        {props.next && <NavLink href={props.next}>次のページ</NavLink>}
      </HStack>
    </VStack>
  )
}

const EntryListItem = ({ entry, ...other }: EntryListItemProps) => {
  const authors: Author[] = getAuthors(entry)
  return (
    <Box
      layerStyle="base"
      px={{ base: 4, md: 3 }}
      pt={{ base: 3, md: 4 }}
      pb={{ base: 2, md: 3 }}
      {...other}
    >
      <Grid
        w="full"
        templateRows="1fr auto"
        gridColumnGap={{ base: 3, md: 12 }}
        templateColumns={{
          base: "auto 100px",
          md: "auto 283px",
        }}
      >
        <GridItem>
          <chakra.h3 w="full" textStyle="media.list_title" mb="2">
            <NavLink href={getEntryPagePath(entry).$url()}>
              {entry.title}
            </NavLink>
          </chakra.h3>
        </GridItem>
        <GridItem rowSpan={{ base: 1, md: 2 }}>
          <Image
            alt=""
            objectFit="cover"
            w={{ base: "100px", md: "283px" }}
            h={{ base: "52px", md: "148px" }}
            src={imgixURL(
              entry.eyecatch?.url,
              `w=${283 * 2}&h=${148 * 2}&fit=crop&crop=faces`
            )}
            ignoreFallback={!!entry.eyecatch}
            borderRadius={{ base: "3px", md: 2 }}
          />
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <VStack align="flex-start" spacing="0.25em">
            <HStack textStyle="note" color="text.gray">
              <chakra.span>
                {authors.map((a) => a.name).join(" | ")}
              </chakra.span>
              <chakra.span>
                {lightFormat(
                  new Date(entry.publishedAt ?? entry.createdAt),
                  "yyyy/MM/dd"
                )}
              </chakra.span>
            </HStack>
            <TagList spacing={2} tags={entry.tag} />
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  )
}

const EntryListItemSkeleton = (props: Omit<EntryListItemProps, "entry">) => {
  return (
    <Box
      layerStyle="base"
      w="full"
      px={{ base: 4, md: 3 }}
      pt={{ base: 3, md: 4 }}
      pb={{ base: 2, md: 3 }}
      {...props}
    >
      <Grid
        w="full"
        templateRows="1fr auto"
        gridColumnGap={{ base: 3, md: 12 }}
        templateColumns={{
          base: "auto 100px",
          md: "auto 283px",
        }}
      >
        <GridItem>
          <Skeleton>
            <chakra.h3 w="full" textStyle="media.list_title" mb="2">
              Loading
            </chakra.h3>
          </Skeleton>
        </GridItem>
        <GridItem rowSpan={{ base: 1, md: 2 }}>
          <chakra.div
            w={{ base: "100px", md: "283px" }}
            h={{ base: "52px", md: "148px" }}
            borderRadius={{ base: "3px", md: 2 }}
            borderWidth="1px"
          />
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 1 }}>
          <VStack align="flex-start" spacing="0.25em">
            <Skeleton>
              <HStack textStyle="note" color="text.gray">
                <chakra.span>Author</chakra.span>
                <chakra.span>2020/01/01</chakra.span>
              </HStack>
            </Skeleton>
            <Skeleton>
              <TagList spacing={2} tags={[{ id: "", name: "ああああ" }]} />
            </Skeleton>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  )
}

type EntryListItemProps = {
  entry: Pick<
    RenderedEntry,
    | "id"
    | "title"
    | "authors"
    | "publishedAt"
    | "tag"
    | "eyecatch"
    | "createdAt"
  >
} & BoxProps
