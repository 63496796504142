import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
  chakra,
  ChakraProps,
} from "@chakra-ui/react"
import { ChevronRightIcon } from "@~/common/components/icon"
import NextLink, { LinkProps as NextLinkProps } from "next/link"

export type PageData = {
  title: string
  url: string | NextLinkProps["href"]
}

export type BreadcrumbSectionProps = {
  pagesData: PageData[]
  breadcrumbProps?: BreadcrumbProps
} & ChakraProps

export const BreadcrumbSection = ({
  pagesData,
  breadcrumbProps,
  ...props
}: BreadcrumbSectionProps) => {
  return (
    <chakra.section
      w="full"
      overflowX={{ base: "scroll", md: "initial" }}
      // スクロールバーの非表示対応
      sx={{
        // For Google Chrome、Safari、Microsoft Edge
        "::-webkit-scrollbar": {
          display: "none",
        },
        // For Firefox
        scrollbarWidth: "none",
      }}
      bgColor="white"
      {...props}
    >
      <Breadcrumb
        maxW="container.content"
        mx="auto"
        separator={
          <ChevronRightIcon
            w="16px"
            h="20px"
            color="moneiro-color-main-orange"
          />
        }
        textStyle="breadcrumb"
        whiteSpace="nowrap"
        p={3}
        {...breadcrumbProps}
      >
        {pagesData.map((data, idx) => (
          <BreadcrumbItem
            key={data.title}
            isCurrentPage={pagesData.length === idx + 1}
          >
            <NextLink href={data.url} passHref legacyBehavior prefetch={false}>
              <BreadcrumbLink
                color={
                  pagesData.length === idx + 1
                    ? "moneiro-color-main-orange"
                    : undefined
                }
              >
                {data.title}
              </BreadcrumbLink>
            </NextLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </chakra.section>
  )
}
