import { createIcon } from "@chakra-ui/react"

export const HatebuIcon = createIcon({
  viewBox: "0 0 40 40",
  path: (
    <>
      <g clipPath="url(#a)">
        <path d="M0 20a20 20 0 1 0 40 0 20 20 0 0 0-40 0Z" fill="#00A4DE" />
        <path
          d="M22.25 20.65a4.3 4.3 0 0 0-3.02-1.36c.96-.2 1.83-.7 2.51-1.39.56-.7.83-1.57.79-2.46a3.9 3.9 0 0 0-2.06-3.66 6.6 6.6 0 0 0-2.1-.67 30.8 30.8 0 0 0-4.3-.2H9.1v18.18h5.14a33.4 33.4 0 0 0 4.46-.2c.8-.12 1.57-.36 2.28-.72a4.21 4.21 0 0 0 1.76-1.72c.42-.79.63-1.67.61-2.56a4.67 4.67 0 0 0-1.09-3.24Zm-8.56-5.7h1.07c1.23 0 2.06.13 2.48.4a1.6 1.6 0 0 1 .64 1.44 1.49 1.49 0 0 1-.69 1.39c-.45.26-1.28.4-2.5.4h-1v-3.64Zm4.23 10.42c-.49.3-1.32.44-2.5.44H13.7v-3.97h1.81c1.22 0 2.03.15 2.48.45.45.3.67.84.67 1.6a1.59 1.59 0 0 1-.73 1.48ZM28.6 24.48a2.3 2.3 0 1 0 0 4.62 2.3 2.3 0 0 0 0-4.62ZM30.6 10.9h-4v12.13h4V10.9Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </>
  ),
})
