import { staticPath_assets_media_default_header_icon_png } from "lib/$asset-path"

import { BoxProps } from "@chakra-ui/react"
import { theme } from "./theme"
import { memoizedGet as get } from "@chakra-ui/utils"

/** デフォルトの記事テーマ */
export const entryThemeDefault: BoxProps["sx"] = {
  "ol, ul": {
    margin: "1em 0",
    paddingLeft: "40px",
    "ol, ul": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  "p,blockquote,ol,ul,.block-outline": get(theme, "textStyles.body"),
  p: {
    margin: "1em 0",
  },
  strong: {
    color: "text.main",
  },
  u: {
    textDecoration: "none",
    bgGradient: "linear(transparent 60%, rgba(255, 159, 53, 0.2) 0%)",
  },
  a: {
    textDecor: "underline",
  },
  ".block-outline": {
    margin: "1em 0",
    borderRadius: "10px",
    borderWidth: "2px",
    borderColor: "line.gray2",
    p: 3,
  },
  ".block-table": {
    margin: "1em 0",
  },
  pre: {
    code: {
      bg: "ui.black",
      color: "aliceblue",
      mx: 0,
      my: "1em",
      py: 1,
      px: 2,
      overflow: "auto",
      display: "block",
    },
  },
  code: {
    border: "1px solid #ddd",
    bg: "white",
    color: "text.main",
    mx: "2px",
    my: 0,
  },
  blockquote: {
    margin: "1em 0",
    bg: "bg.gray2",
    borderRadius: "10px",
    px: 9,
    pt: 3,
    pb: 3,
    pos: "relative",
    _before: {
      content: `"“"`,
      pos: "absolute",
      top: 4,
      left: 3,
      color: "text.gray",
      display: "block",
      w: "20px",
      lineHeight: "17px",
      fontSize: "50px",
    },
    _after: {
      content: `"”"`,
      pos: "absolute",
      right: 3,
      bottom: 0,
      fontSize: "60px",
      display: "block",
      w: "20px",
      lineHeight: "17px",
      color: "text.gray",
    },
  },
  h2: {
    ...get(theme, "textStyles.h3"),
    mb: "1em",
  },
  h3: {
    /** PC: textStyles.h4, SP: textStyles.h3SP  */
    fontFamily: "body",
    fontWeight: "bold",
    fontSize: { base: "16px", md: "18px" },
    lineHeight: "24px",
    letterSpacing: { base: "0.04em", md: "0.08em" },
    mb: "1em",
  },
  h4: {
    textStyle: { base: "14-bold", md: "16-bold" },
    mb: "1em",
  },
  "h5,h6": {
    ...get(theme, "textStyles.h4"),
    mb: "1em",
  },
  "nav.toc": {
    fontSize: "14px",
    h3: {
      fontWeight: "bold",
      fontSize: "14px",
    },
    bg: "bg.gray2",
    p: 4,
    pb: 1,
    my: 4,
    ol: {
      listStyle: "none",
      color: "text.secondary",
      fontWeight: "bold",
      fontSize: "inherit",
      paddingLeft: 0,
    },
  },
  ".headingWrapper": {
    "&.h0Wrapper": {
      my: 0,
    },
    "&.h2Wrapper": {
      my: 6,
    },
    "&.h3Wrapper": {
      my: 6,
    },
  },
  /** リンクにお姉さん登場 */
  ".decor__l2": {
    display: "block",
    borderWidth: "3px",
    borderColor: "moneiro-color-main-orange",
    borderRadius: "m",
    backgroundRepeat: "no-repeat",
    padding: {
      base: "2em calc(3em + 15px) 2em 10px",
      md: `2em ${3 + 3.5}em 2em 1em`,
    },
    backgroundPosition: "right 1em center",
    backgroundSize: "auto 5em",
    backgroundImage: `url(${staticPath_assets_media_default_header_icon_png})`,
  },
  ".decor__l3": {
    display: "block",
    color: "white",
    p: 1,
    px: 2,
    borderRadius: "5px",
    backgroundColor: "ui.main",
  },
  ".decor__l4": {
    pl: 2,
    py: "2px",
    borderLeftWidth: "5px",
    borderColor: "moneiro-color-line-gray-strong",
  },
  "div.ad": {
    h2: {
      fontSize: { base: "30px", md: "37px" },
      lineHeight: { base: "10px", md: "24px" },
      fontWeight: "bold",
    },
    "div.card": {
      fontSize: "12px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
      fontWeight: "bold",
    },
    "span.tag": {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "24px",
      letterSpacing: "0.08em",
    },
    a: {
      textDecor: "none",
    },
  },
  ".line-banner h3": {
    marginBottom: 0,
  },
}
