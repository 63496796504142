import { createIcon } from "@chakra-ui/react"

export const TwitterIcon = createIcon({
  viewBox: "0 0 30 31",
  path: (
    <>
      <g clipPath="url(#clip0_2440_53871)">
        <path
          d="M15.875 30C24.1593 30 30.875 23.2843 30.875 15C30.875 6.71573 24.1593 0 15.875 0C7.59073 0 0.875 6.71573 0.875 15C0.875 23.2843 7.59073 30 15.875 30Z"
          fill="#13161C"
        />
        <g clipPath="url(#clip1_2440_53871)">
          <path
            d="M17.1593 14.0919L22.185 8.25H20.9941L16.6303 13.3225L13.145 8.25H9.125L14.3955 15.9205L9.125 22.0467H10.316L14.9244 16.69L18.6051 22.0467H22.6251L17.1592 14.0919H17.1595H17.1593ZM15.528 15.988L14.994 15.2242L10.7451 9.14651H12.5744L16.0034 14.0514L16.5374 14.8152L20.9946 21.1908H19.1654L15.5282 15.9882V15.9879L15.528 15.988Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2440_53871">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1_2440_53871">
          <rect
            width="13.5"
            height="13.7967"
            fill="white"
            transform="translate(9.125 8.25)"
          />
        </clipPath>
      </defs>
    </>
  ),
})
