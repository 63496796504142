import { Box } from "@chakra-ui/react"
import { OwataLabel } from "components/lp/widgets/OwataLabel"
import { RenderedEntry } from "../util/types"

export type SummaryProps = {
  summary: RenderedEntry["summary"]
}

export const Summary = ({ summary }: SummaryProps) => {
  if (!summary) return null

  return (
    <Box
      border="2px solid"
      borderColor={"moneiro-color-line-gray-basic"}
      rounded="5px"
      my={4}
    >
      <OwataLabel
        bgColor="white"
        mt="-13px"
        w="220px"
        ml={2}
        labelProps={{ textStyle: "14-bold", px: 0 }}
        handProps={{
          w: "14px",
          h: "2px",
          bgColor: "moneiro-color-line-gray-basic",
        }}
      >
        この記事を読んでわかること
      </OwataLabel>
      <Box
        dangerouslySetInnerHTML={{ __html: summary }}
        pt={3}
        pb={4}
        px={2}
        sx={{
          ul: {
            textStyle: "14-reg",
            listStyle: "none",
          },
          li: {
            position: "relative",
            pl: 4,
            ":before": {
              content: '""',
              width: "6px",
              height: "6px",
              display: "inline-block",
              backgroundColor: "moneiro-color-main-orange",
              position: "absolute",
              top: "9px",
              left: "5px",
              rounded: "full",
            },
          },
        }}
      />
    </Box>
  )
}
