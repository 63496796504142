import {
  Box,
  BoxProps,
  chakra,
  Divider,
  Flex,
  Grid,
  HStack,
  Stack,
  Text,
  TextProps,
  VStack,
  Wrap,
} from "@chakra-ui/react"
import {
  FacebookShareButton,
  HatenaBookmarkShareButton,
  LineShareButton,
  TwitterShareButton,
} from "@~/common/components"
import { imgixSquareRoundedImageLoader } from "@~/common/lib/imgix"
import { getEntryPageURL } from "@~/common/lib/media/urls"
import { Author } from "@~/common/lib/microcms/media"
import { NextImage, NextImageInlineWrapper, Visible } from "components/system"
import { pagesPath } from "lib/$path"
import { getEntryPagePath } from "media/lib/urls"
import NextLink from "next/link"
import { TagList } from "./TagList"
import { RenderedEntry } from "./util/types"

export type EntryMetaBoxProps = BoxProps & {
  entry: Pick<
    RenderedEntry,
    | "id"
    | "publishedAtLabel"
    | "createdAtLabel"
    | "revisedAtLabel"
    | "category"
    | "tags"
    | "title"
  > & {
    authors: Pick<Author, "id" | "name" | "title" | "icon">[]
    supervisor?: Pick<Author, "id" | "name" | "title" | "icon"> | null
  }
  authorLink?: string
  supervisorLink?: string
  pageTitle: string
}

export const EntryMetaBox = ({
  entry,
  supervisorLink,
  authorLink,
  pageTitle,
  ...props
}: EntryMetaBoxProps) => {
  let authors = entry.authors
  const supervisor = entry.supervisor ?? null
  const authorRoleLabel = "執筆者"
  let supervisorRoleLabel = "監修者"
  // 執筆者と監修者が同じ場合は、「執筆・監修」とまとめる
  if (supervisor) {
    const supervisorAuthor = authors.find((a) => a.id === supervisor?.id)
    if (supervisorAuthor) {
      supervisorRoleLabel = "執筆・監修"
      authors = authors.filter((a) => a.id !== supervisorAuthor.id)
    }
  }
  const socialIconProps = { w: { base: 8, md: 6 }, h: { base: 8, md: 6 } }

  const canonical = getEntryPageURL(entry)
  return (
    <Box {...props} alignItems="flex-start">
      <HStack mb={5}>
        {entry.category && (
          <NextLink
            legacyBehavior
            passHref
            href={pagesPath.media.category._id(entry.category.id).$url()}
          >
            <Text
              as="a"
              color="white"
              bgColor="ui.main"
              textStyle="f12Bold"
              py="1px"
              px={{ base: 1, md: 2 }}
              rounded="4px"
              _hover={{ opacity: 0.8 }}
            >
              {entry.category.name}
            </Text>
          </NextLink>
        )}
        <NextLink legacyBehavior passHref href={getEntryPagePath(entry).$url()}>
          <Text as="a" h="50%" textStyle="note" color="text.gray">
            <span>{entry.publishedAtLabel || entry.createdAtLabel}</span>
            {entry.publishedAtLabel !== entry.revisedAtLabel &&
              entry.revisedAtLabel && (
                <>
                  &nbsp;(
                  <span>
                    最終更新:
                    {entry.revisedAtLabel}
                  </span>
                  )
                </>
              )}
          </Text>
        </NextLink>
      </HStack>
      <Grid
        gridTemplateAreas={{
          base: `"author" "tags" "social" `,
          md: `"author social" "tags tags"`,
        }}
      >
        <VStack>
          <Stack
            spacing={0}
            w="full"
            align="left"
            textStyle="note"
            color="text.gray"
            gridArea="author"
            direction={{ base: "column", md: "row" }}
          >
            {authors.length > 0 && (
              <AuthorRow
                link={authorLink}
                roleLabel={authorRoleLabel}
                people={authors.slice(0, 1)}
                noTitle
              />
            )}
            {supervisor && (
              <AuthorRow
                link={supervisorLink}
                roleLabel={supervisorRoleLabel}
                people={[supervisor]}
                hasLeftLine={authors.length > 0}
              />
            )}
          </Stack>
        </VStack>
        <TagList
          _link={{ textStyle: "14-reg" }}
          pt={2}
          pb={4}
          tags={entry.tags}
          gridArea="tags"
        />
        <Wrap
          justify="center"
          spacing={{ base: 4, md: 0 }}
          minW="170px"
          ml={"auto"}
          mr={{ base: "auto", md: 0 }}
          flexWrap="nowrap"
          gridArea="social"
        >
          <TwitterShareButton
            urlToShare={canonical}
            hashtags={["マネイロ"]}
            _iconProps={socialIconProps}
          />
          <LineShareButton
            urlToShare={canonical}
            _iconProps={socialIconProps}
          />
          <FacebookShareButton
            urlToShare={canonical}
            _iconProps={socialIconProps}
          />
          <HatenaBookmarkShareButton
            urlToShare={canonical}
            title={entry.title}
            _iconProps={socialIconProps}
          />
        </Wrap>
      </Grid>
    </Box>
  )
}

const AuthorRow = ({
  people,
  link,
  roleLabel,
  noTitle,
  hasLeftLine,
  ...props
}: {
  people: Pick<Author, "name" | "title" | "icon" | "id">[]
  link?: string
  roleLabel: string
  noTitle?: boolean
  hasLeftLine?: boolean
} & TextProps) => {
  return (
    <Text
      as={link ? "a" : undefined}
      href={link}
      display="flex"
      alignItems={"center"}
      textStyle="f12"
      {...props}
    >
      {hasLeftLine && (
        <Visible mdOnly>
          <chakra.span color="text.gray2" textStyle="f16Bold" mx={1}>
            ｜
          </chakra.span>
        </Visible>
      )}
      <chakra.div mr={2} whiteSpace="nowrap">
        {roleLabel}:
      </chakra.div>
      <HStack
        divider={
          <Divider h={3} borderColor={"text.gray2"} orientation="vertical" />
        }
        wrap="wrap"
        rowGap={1}
      >
        {people.map((author) => (
          <Flex
            key={author.id}
            whiteSpace={"nowrap"}
            align="center"
            columnGap={1}
          >
            {author.icon && (
              <NextImageInlineWrapper w={35} h={35}>
                <NextImage
                  src={author.icon.url}
                  loader={imgixSquareRoundedImageLoader}
                  layout="fixed"
                  width={35}
                  height={35}
                  alt={author.name}
                />
              </NextImageInlineWrapper>
            )}
            {author.name}
            {!noTitle &&
              (author.title ? ` (${shortenTitle(author.title)})` : "")}
          </Flex>
        ))}
      </HStack>
    </Text>
  )
}

/** 役職を短かくする
 *
 * - 適当な区切り文字で区切って1つめのみを取り出す
 */
const shortenTitle = (input: string) => {
  return input.split(/(\/|／|\||\,|，)/, 1)[0] ?? input
}
