import {
  Box,
  chakra,
  HStack,
  Image,
  LinkBox,
  LinkBoxProps,
  LinkOverlay,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { imgixURL } from "@~/common/lib/imgix"
import { Entry } from "@~/common/lib/microcms/media"
import { Category } from "@~/common/lib/microcms/media/types/categories"
import { Tag } from "@~/common/lib/microcms/media/types/tags"
import { Overwrite } from "@~/common/types/util"
import { NextImageInlineWrapper } from "components/system"
import lightFormat from "date-fns/lightFormat"
import { getEntryPagePath } from "media/lib/urls"
import NextLink from "next/link"

export type ArticleSimpleCardProps = {
  entry: Overwrite<
    Pick<Entry, "id" | "eyecatch" | "title" | "publishedAt" | "createdAt">,
    {
      authors: { name: string }[]
      category?: Pick<Category, "name">
      tags?: Pick<Tag, "id" | "name">[]
    }
  >
  nextLinkPrefetch?: false
  showPublishedAt?: boolean
} & LinkBoxProps & {
    noCategory?: boolean
    noTags?: boolean
  }

export const ArticleSimpleCard = ({
  entry,
  nextLinkPrefetch,
  noCategory,
  noTags,
  showPublishedAt = false,
  ...props
}: ArticleSimpleCardProps) => {
  const { title, eyecatch, authors, category, tags, publishedAt, createdAt } =
    entry

  return (
    <LinkBox as="article" minW={{ base: "345px", lg: "405px" }} {...props}>
      <Stack spacing={2} direction={"row"}>
        <VStack flexShrink={0} order={{ base: 2, md: 1 }}>
          <NextImageInlineWrapper>
            <Image
              src={imgixURL(
                eyecatch?.url,
                `w=${eyecatch?.width}&h=${eyecatch?.height}&fit=crop&crop=faces`
              )}
              alt={title}
              objectFit="cover"
              borderRadius="5px"
              w={{ base: "130px", md: "168px" }}
              h={{ base: "68px", md: "88px" }}
              flexShrink={0}
              ignoreFallback={!!eyecatch}
              fallback={
                <Box
                  w={"114px"}
                  h={"60px"}
                  bgColor="moneiro-color-fill-gray-basic"
                  borderRadius="5px"
                />
              }
            />
          </NextImageInlineWrapper>
          {showPublishedAt && (
            <chakra.div
              fontSize="10px"
              color="text.gray"
              ml={{ base: "auto", md: 1 }}
              order={{ base: 3, md: 2 }}
              marginTop={{ base: 1, md: 0 }}
            >
              {lightFormat(new Date(publishedAt ?? createdAt), "yyyy/MM/dd")}
            </chakra.div>
          )}
        </VStack>

        <Stack spacing={0} order={{ base: 1, md: 2 }}>
          <chakra.h3 textStyle={{ base: "12-bold", md: "14-bold" }} mb="4px">
            <NextLink
              href={getEntryPagePath(entry).$url()}
              passHref
              prefetch={nextLinkPrefetch}
              legacyBehavior
            >
              <LinkOverlay>{title}</LinkOverlay>
            </NextLink>
          </chakra.h3>
          {!noTags && tags && tags?.length > 0 && (
            <Wrap w="full" spacingX="8px" spacingY={0} mb="4px">
              {tags.map((tag) => {
                return (
                  <WrapItem
                    fontSize={{ base: "10px", md: "11px" }}
                    lineHeight={{ base: "14px", md: "17px" }}
                    color="moneiro-color-main-orange"
                    key={tag.id}
                  >
                    #{tag.name}
                  </WrapItem>
                )
              })}
            </Wrap>
          )}
          <HStack flexWrap="wrap">
            {!noCategory && category && (
              <Text
                color="white"
                bgColor="ui.main"
                textStyle={{ base: "f10", md: "f12" }}
                fontWeight={600}
                lineHeight="18px"
                py={{ base: 0, md: 0.5 }}
                px={{ base: 1, md: 1 }}
                rounded="4px"
              >
                {category.name}
              </Text>
            )}
            <chakra.div
              fontWeight="500"
              textStyle={{ base: "f10", md: "f12" }}
              color="text.gray"
              textOverflow="ellipsis"
            >
              {authors.map((author) => author.name).join("｜")}
            </chakra.div>
          </HStack>
        </Stack>
      </Stack>
    </LinkBox>
  )
}
