import { stringify } from "querystring"
import { StaticRoutes } from "../constants"
import { Author, Entry, FeatureEntry } from "../microcms/media"
import { Tag } from "../microcms/media/types/tags"

export const getEntryPageURL = (entry: Pick<Entry, "id">, media?: string) =>
  `${StaticRoutes.media.Root}${getInternalEntryPagePath(entry)}${
    media ? `?media=${media}` : ""
  }`

export const getIndexPageURL = () => StaticRoutes.media.Root

export const getCategoryIndexPageURL = () =>
  `${StaticRoutes.media.Root}${getInternalCategoryIndexPagePath()}`

export const getCategoryEntryListPageURL = (categoryID: string) =>
  `${StaticRoutes.media.Root}${getInternalCategoryEntryListPagePath(
    categoryID
  )}`

export const getTagIndexPageURL = () =>
  `${StaticRoutes.media.Root}${getInternalTagIndexPagePath()}`

export const getTagEntryListPageURL = (tag: Pick<Tag, "id">) =>
  `${StaticRoutes.media.Root}${getInternalTagEntryListPagePath(tag.id)}`

export const getAuthorPageURL = (author: Pick<Author, "id">, media?: string) =>
  `${StaticRoutes.media.Root}${getInternalAuthorPagePath(author)}${
    media ? `?media=${media}` : ""
  }`

export const getFeatureEntryIndexURL = () =>
  `${StaticRoutes.media.Root}/feature`

export const getFeatureEntryPageURL = (
  entry: Pick<FeatureEntry, "id">,
  media?: string
) =>
  `${StaticRoutes.media.Root}${getInternalFeatureEntryPagePath(entry)}${
    media ? `?media=${media}` : ""
  }`

export const getAuthorPagePath = (author: Pick<Author, "id">) =>
  `${basePath}${getInternalAuthorPagePath(author)}`

const basePath = "/media"

export const getEntryPagePath = (entry: Pick<Entry, "id">) =>
  `${basePath}${getInternalEntryPagePath(entry)}`

export const getAdEntryPagePath = (entry: Pick<Entry, "id">) =>
  `${getInternalAdEntryPagePath(entry)}`

export const getIndexPagePath = () => basePath

export const getSearchPagePath = (query: string, page?: number) =>
  `${basePath}${getInternalSearchPagePath(query, page)}`

export const getTagIndexPagePath = () =>
  `${basePath}${getInternalTagIndexPagePath()}`

export const getFeatureEntryPagePath = (entry: Pick<FeatureEntry, "id">) =>
  `${basePath}${getInternalFeatureEntryPagePath(entry)}`

export const getInternalSearchPagePath = (query: string, page?: number) =>
  `/search?${stringify({
    q: query,
    page: page && page > 0 ? page : undefined,
  })}`

export const getInternalCategoryIndexPagePath = () => `/category`

export const getInternalCategoryEntryListPagePath = (categoryID: string) =>
  `/category/${categoryID}`

export const getInternalTagIndexPagePath = () => `/tag`

export const getInternalTagEntryListPagePath = (tagID: string) =>
  `/tag/${tagID}`

const getInternalEntryPagePath = (entry: Pick<Entry, "id">) =>
  `/article/${entry.id}`

const getInternalAdEntryPagePath = (entry: Pick<Entry, "id">) =>
  `/feature/article/${entry.id}`

const getInternalAuthorPagePath = (entry: Pick<Author, "id">) =>
  `/author/${entry.id}`

const getInternalFeatureEntryPagePath = (entry: Pick<FeatureEntry, "id">) =>
  `/feature/${entry.id}`
