import { extendTheme } from "@chakra-ui/react"
import { theme as baseTheme } from "@~/common/components/system/theme"
import { Roboto } from "next/font/google"

const roboto = Roboto({
  weight: "400",
  subsets: ["latin"],
})

export const theme = extendTheme(
  {
    config: {
      cssVarPrefix: "o",
      initialColorMode: "light",
      useSystemColorMode: false,
    },
    fonts: {
      roboto: roboto.style.fontFamily,
    },
    textStyles: {
      media: {
        list_title: {
          fontSize: { base: "14px", md: "20px" },
          lineHeight: { base: "22px", md: "28px" },
          letterSpacing: { md: "0.08em" },
          fontWeight: "bold",
          fontFamily: "body",
          textDecor: "underline",
          color: "text.secondary",
        },
        entry_tag: {
          fontSize: "12px",
          fontWeight: "medium",
          lineHeight: { base: "20px", md: "22px" },
          letterSpacing: "0.05em",
        },
      },
    },
  },
  baseTheme
)

export type Theme = typeof theme
