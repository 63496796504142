import {
  Button,
  ButtonProps,
  Icon,
  IconButton,
  IconProps,
} from "@chakra-ui/react"
import qs from "querystring"
import { FacebookIcon } from "./icon/social/facebook"
import { HatebuIcon } from "./icon/social/hatebu"
import { LineIcon } from "./icon/social/line"
import { TwitterIcon } from "./icon/social/twitter"

export const FacebookShareButton = ({
  urlToShare,
  as: ButtonComponent = Button,
  _iconProps,
  ...other
}: ButtonProps & {
  urlToShare: string
  _iconProps?: IconProps
}) => {
  return (
    <IconButton
      aria-label="facebook"
      variant="unstyled"
      overflow={"visible"}
      icon={<Icon as={FacebookIcon} w={8} h={8} {..._iconProps} />}
      onClick={() => {
        const url = `https://www.facebook.com/sharer/sharer.php?${new URLSearchParams(
          {
            u: urlToShare,
          }
        )}`
        window.open(url, "_target", "noopener")
      }}
    />
  )
}

export const TwitterShareButton = ({
  urlToShare,
  text,
  hashtags,
  as: ButtonComponent = Button,
  _iconProps,
  ...other
}: ButtonProps & {
  urlToShare: string
  text?: string
  hashtags?: string[]
  _iconProps?: IconProps
}) => {
  return (
    <IconButton
      aria-label="twitter"
      variant="unstyled"
      overflow={"visible"}
      icon={<Icon as={TwitterIcon} w={8} h={8} {..._iconProps} />}
      onClick={() => {
        const url = `https://twitter.com/intent/tweet?${qs.stringify({
          url: urlToShare,
          text,
          hashtags: hashtags?.join(","),
        })}`
        if (!window) return
        window.open(url, "_target", "noopener")
      }}
    />
  )
}

export const HatenaBookmarkShareButton = ({
  urlToShare,
  title,
  as: ButtonComponent = Button,
  _iconProps,
  ...other
}: ButtonProps & {
  urlToShare: string
  title: string
  _iconProps?: IconProps
}) => {
  return (
    <IconButton
      aria-label="hatena-bookmark"
      variant="unstyled"
      isRound={true}
      icon={<Icon as={HatebuIcon} w={8} h={8} {..._iconProps} />}
      onClick={() => {
        const url = `http://b.hatena.ne.jp/entry/panel/?${qs.stringify({
          url: urlToShare,
          btitle: encodeURIComponent(title),
        })}`
        if (!window) return
        window.open(
          url,
          "hatena_bookmark_panel_popup",
          "width=512,height=600,menubar=no,toolbar=no,resizable=yes"
        )
      }}
    />
  )
}

export const LineShareButton = ({
  urlToShare,
  _iconProps,
  ...other
}: ButtonProps & {
  urlToShare: string
  _iconProps?: IconProps
}) => {
  return (
    <IconButton
      aria-label="line"
      variant="unstyled"
      isRound={true}
      icon={<Icon as={LineIcon} w={8} h={8} {..._iconProps} />}
      onClick={() => {
        const url = `https://line.me/R/share?${qs.stringify({
          text: urlToShare,
        })}`
        if (!window) return
        window.open(url, "_target", "noopener")
      }}
    />
  )
}
