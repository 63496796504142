import { ChevronLeftIcon } from "@chakra-ui/icons"
import {
  Box,
  BoxProps,
  Button,
  chakra,
  HStack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import {
  FacebookShareButton,
  HatenaBookmarkShareButton,
  LineShareButton,
  TwitterShareButton,
} from "@~/common/components"
import { NextImage, NextImageInlineWrapper } from "@~/common/components/system"
import { imgixSquareImageLoader } from "@~/common/lib/imgix"
import { getEntryPageURL } from "@~/common/lib/media/urls"
import { Author } from "@~/common/lib/microcms/media"
import { Overwrite } from "@~/common/types/util"
import { pagesPath } from "lib/$path"
import NextLink from "next/link"
import type { Simplify } from "type-fest"
import { RenderedEntry } from "./util/types"

export type EntryFootMetaBoxProps = BoxProps & {
  entry: Overwrite<
    Pick<RenderedEntry, "id" | "title">,
    {
      supervisor?: AuthorProfileProps["author"] | null
      authors: AuthorProfileProps["author"][]
    }
  >
  pageTitle: string
}

export const EntryFootMetaBox = ({
  entry,
  pageTitle,
  ...props
}: EntryFootMetaBoxProps) => {
  let authors: Map<string, AuthorProfileProps["author"]> = new Map(
    entry.authors.map((a) => [a.id, a])
  ) // Mapの順番は保持される
  const supervisor = entry.supervisor
  // supervisorがauthorsに含まれている場合は執筆・監修として扱い、authorsからは削除
  const authorRoleLabel = "執筆者"
  let supervisorRoleLabel = "監修者"
  if (supervisor && authors.has(supervisor.id)) {
    supervisorRoleLabel = "執筆・監修"
    authors.delete(supervisor.id)
  }

  const canonical = getEntryPageURL(entry)
  return (
    <VStack spacing={{ base: 4, md: 6 }} {...props}>
      <VStack as="dl" w="full" align="flex-start" mb={{ base: 4, md: 6 }}>
        {supervisor && (
          <>
            <chakra.dt>
              <Text as="h5" textStyle="h4" id="supervisor">
                {supervisorRoleLabel}
              </Text>
            </chakra.dt>
            <chakra.dd mb={{ base: 4, md: 6 }}>
              <AuthorProfile author={supervisor} />
            </chakra.dd>
          </>
        )}
        {authors.size > 0 && (
          <chakra.dt>
            <Text as="h5" textStyle="h4" id="author">
              {authorRoleLabel}
            </Text>
          </chakra.dt>
        )}
        {Array.from(authors.entries()).map(([_, author]) => (
          <chakra.dd key={author.id}>
            <AuthorProfile author={author} />
          </chakra.dd>
        ))}
      </VStack>
      <Wrap justify="center" spacing={3}>
        <TwitterShareButton
          text={pageTitle}
          urlToShare={canonical}
          hashtags={["マネイロ"]}
        />
        <LineShareButton urlToShare={canonical} />
        <FacebookShareButton urlToShare={canonical} />
        <HatenaBookmarkShareButton urlToShare={canonical} title={entry.title} />
      </Wrap>
      <chakra.div py={2} textAlign="center">
        <NextLink
          href={pagesPath.media.$url()}
          passHref
          prefetch={false}
          legacyBehavior
        >
          <Button
            as="a"
            size="lg"
            w="full"
            maxW="315px"
            variant="outline"
            justifyContent="space-between"
            leftIcon={<ChevronLeftIcon boxSize="24px" />}
            rightIcon={<Box boxSize="24px" />}
            mx="auto"
            _hover={{
              bg: "moneiro-color-line-gray-basic",
            }}
          >
            一覧へもどる
          </Button>
        </NextLink>
      </chakra.div>
    </VStack>
  )
}

type AuthorProfileProps = {
  author: Simplify<
    Pick<Author, "id" | "icon" | "name" | "title"> & {
      short_profile_html: string
    }
  >
}

const AuthorProfile = ({ author }: AuthorProfileProps) => {
  const avatarProps = { w: "80px", minW: "40px", mr: "auto" }
  return (
    <HStack w="full" spacing={3} align="flex-start">
      <>
        {author.icon?.url && (
          <NextImageInlineWrapper {...avatarProps}>
            <NextImage
              src={author.icon.url}
              loader={imgixSquareImageLoader}
              width={80}
              height={80}
              alt={author.name}
            />
          </NextImageInlineWrapper>
        )}
      </>
      <VStack spacing={2} w="full" align="left">
        <Wrap spacing={2} align="center">
          <WrapItem>
            <Text
              as="h6"
              textStyle={{ base: "14-bold", md: "18-bold" }}
              whiteSpace="nowrap"
            >
              <NextLink
                href={pagesPath.media.author._author_id(author.id).$url()}
                legacyBehavior
              >
                {author.name}
              </NextLink>
            </Text>
          </WrapItem>
          <WrapItem>
            <Text as="span" textStyle="14-reg">
              {author.title}
            </Text>
          </WrapItem>
        </Wrap>
        {author.short_profile_html && (
          <chakra.div
            textStyle="14-reg"
            sx={{
              a: {
                textDecor: "underline",
              },
            }}
            dangerouslySetInnerHTML={{ __html: author.short_profile_html }}
          />
        )}
      </VStack>
    </HStack>
  )
}
