import { createIcon } from "@chakra-ui/react"

export const FacebookIcon = createIcon({
  viewBox: "0 0 14222 14222",
  path: (
    <>
      <circle cx="7111" cy="7112" r="7111" fill="#1977f3" />
      <path
        d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z"
        fill="#fff"
      />
    </>
  ),
})
