import {
  Box,
  chakra,
  HTMLChakraProps,
  StackProps,
  VStack,
} from "@chakra-ui/react"
import React from "react"

export type PageTitleProps = {
  mainText: string
  mainStyle?: HTMLChakraProps<"h2">
} & StackProps

export const PageTitle = ({
  mainText,
  mainStyle,
  as,
  ...props
}: PageTitleProps) => {
  return (
    <VStack spacing={2} mb={6} {...props}>
      <chakra.h2 textAlign="center" textStyle="24-bold" as={as} {...mainStyle}>
        {mainText}
      </chakra.h2>
      <Box
        w={{ base: 6, md: 8 }}
        h="4px"
        bgColor="moneiro-color-main-orange"
        mx="auto"
      ></Box>
    </VStack>
  )
}
