import { Box, chakra, StackProps, Text, VStack } from "@chakra-ui/react"
import { NextImage, NextImageInlineWrapper } from "@~/common/components/system"
import { imgixFitMaxImageLoader } from "@~/common/lib/imgix"
import {
  BreadcrumbSection,
  PageData,
} from "components/common-ui/BreadcrumbSection"
import { pagesPath } from "lib/$path"
import { entryThemeDefault } from "media/components/entryThemeDefault"
import { getCategoryIndexPagePath, getEntryPagePath } from "media/lib/urls"
import { Simplify } from "type-fest"
import { EntryFootMetaBox, EntryFootMetaBoxProps } from "./EntryFootMetaBox"
import { EntryMetaBox, EntryMetaBoxProps } from "./EntryMetaBox"
import {
  RecommendEntryList,
  RecommendEntryListProps,
} from "./RecommendEntryList"
import { Summary } from "./Summary"
import { RenderedEntry } from "./util/types"

export type SingularProps = {
  entry: Simplify<
    Readonly<
      Pick<
        RenderedEntry,
        | "id"
        | "title"
        | "eyecatch"
        | "headingHtmlAST"
        | "bodyHtmlAST"
        | "summary"
      > & {
        recommend_entries: RecommendEntryListProps["entries"]
      } & EntryMetaBoxProps["entry"] &
        EntryFootMetaBoxProps["entry"]
    >
  >
  pageTitle: string
  heading?: JSX.Element
  body: JSX.Element
}

/** 個別表示用コンポーネント */
export const Singular = (props: SingularProps) => {
  const containerProps: StackProps = {
    w: "full",
    maxW: "container.secondContent",
    spacing: { base: 2, md: 4 },
    align: "normal",
    px: 3,
  }

  let pagesData: PageData[] = [
    { title: "マネイロメディア", url: pagesPath.media.$url() },
    {
      title: props.entry.title,
      url: getEntryPagePath(props.entry).$url(),
    },
  ]
  if (props.entry.category)
    pagesData.splice(1, 0, {
      title: props.entry.category.name,
      url: getCategoryIndexPagePath(props.entry.category.id).$url(),
    })

  return (
    <>
      <VStack {...containerProps}>
        <BreadcrumbSection pagesData={pagesData} breadcrumbProps={{ p: 0 }} />
        {props.entry.eyecatch && (
          <NextImageInlineWrapper>
            <NextImage
              priority
              width={685}
              height={360}
              layout="responsive"
              objectFit="cover"
              loader={imgixFitMaxImageLoader}
              alt={props.entry.title}
              src={props.entry.eyecatch.url}
              borderRadius={{ base: 5, md: 10 }}
            />
          </NextImageInlineWrapper>
        )}
        <chakra.h1 textStyle="widgetHeader">{props.entry.title}</chakra.h1>
        <EntryMetaBox
          pageTitle={props.pageTitle}
          authorLink="#author"
          supervisorLink="#supervisor"
          entry={props.entry}
          zIndex={0}
          mb={{ base: 2, md: 4 }}
        />
        <div>
          {props.heading && <Box sx={entryThemeDefault}>{props.heading}</Box>}
          {props.entry.summary && <Summary summary={props.entry.summary} />}
          <Box sx={entryThemeDefault}>{props.body}</Box>
        </div>

        <VStack
          textStyle="f12"
          color="#9a9a9a"
          mb={{ base: 4, md: 8 }}
          align="flex-start"
        >
          <Text>
            ※本記事の内容は記事公開時や更新時の情報です。現行と期間や条件が異なる場合がございます
          </Text>
          <Text>
            ※本記事の内容は予告なしに変更することがあります。予めご了承ください
          </Text>
        </VStack>
      </VStack>
      <RecommendEntryList
        entries={props.entry.recommend_entries}
        py={6}
        px={3}
        bgColor="moneiro-color-fill-gray-basic"
      />
      <EntryFootMetaBox
        pageTitle={props.pageTitle}
        {...containerProps}
        pt={8}
        px={3}
        entry={props.entry}
      />
    </>
  )
}
