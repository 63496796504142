import {
  Link,
  LinkProps,
  Wrap,
  WrapItem,
  WrapItemProps,
  WrapProps,
} from "@chakra-ui/react"
import NextLink from "next/link"
import React from "react"
import { getTagEntryListPageURL } from "@~/common/lib/media/urls"
import { Tag } from "@~/common/lib/microcms/media/types/tags"

export const TagList = ({
  tags,
  _tag,
  _link,
  ...other
}: {
  tags: Pick<Tag, "id" | "name">[]
  _tag?: WrapItemProps
  _link?: LinkProps
} & WrapProps) => (
  <Wrap {...other}>
    {tags.map((t: Tag) => (
      <WrapItem key={t.id} textStyle="media.entry_tag" {..._tag}>
        <NextLink passHref href={getTagEntryListPageURL(t)} legacyBehavior>
          <Link
            color="text.mainDark"
            border="1px solid"
            borderColor="moneiro-color-line-gray-basic"
            rounded="30px"
            px={3}
            py={1}
            {..._link}
          >
            #{t.name}
          </Link>
        </NextLink>
      </WrapItem>
    ))}
  </Wrap>
)
